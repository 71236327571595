$(function() {

  // open predefined item on page load
  $('.accordion__item--opened').children('.accordion__content').show();

  $(document).on('click', '.js-accordion__title', function() {
    var $thisContent = $(this).next();

    // hide content of all other accordion items
    $(this).closest('.js-accordion__item')
           .siblings()
           .removeClass('accordion__item--opened')
           .children('.js-accordion__content')
           .slideUp();

    // show content of clicked accordion item
    $thisContent.not(':animated')
                .slideToggle()
                .parent()
                .toggleClass('accordion__item--opened');
  });

});


/*** simple accordion for TESTBOX page ***/
$(function() {

  // open predefined item on page load
  $('.accordion__item--opened').children('.accordion__content').show();

  $(document).on('click', '.js-accordion__heading', function() {
    var $thisContent = $(this).next();

    // show content of clicked accordion item
    $thisContent.not(':animated')
                .slideToggle()
                .parent()
                .toggleClass('accordion__item--opened');
  });

});

$(function() {

  // hide dropdown when click on letter
  $(document).on('click', '.brands__dropdown-link', function(){
    $(this).closest('.js_hasdrop')
           .removeClass('active')
           .find('.dropdown')
           .stop()
           .animate({'height':0},250,function(){
             $(this).find('.active').removeClass('active');
           });
  });

});

$(document).ready(function(){
  //CATALOG FILTERS ACCORDION
  var	filtersWrapper = $('.toggle-items__wrapper'),
    filterTrigger = $('.js-filter-trigger');
    filterTrigger.click(function(e){
        if ($(this).parent('.toggle-wrapper').hasClass('active')) {
            $(this).parent('.toggle-wrapper').removeClass('active');
            $(this).next('.js-filter-body').slideUp(200);
        }
        else {
            $(this).next('.js-filter-body').slideDown(600);
            $(this).parent('.toggle-wrapper').addClass('active');
        }
     e.preventDefault();
  });
});

//SLIDE MOBILE FILTER
$(function() {
  $('.js-filter-toggle').on('click', function(){
    $('.js-filter-block').stop().slideToggle();
  });
});

$(function() {
  $(document).on('click', '.js-filter-toggle', function() {
    // $('.js-filter-body').slideToggle();
    $(this).parents('.js-filter-marker').toggleClass('filter--opened')
  });
});

var GetNoun = function(number, one, two, five) {
    number = Math.abs(number);
    number %= 100;
    if (number >= 5 && number <= 20) {
        return five;
    }
    number %= 10;
    if (number == 1) {
        return one;
    }
    if (number >= 2 && number <= 4) {
        return two;
    }
    return five;
}

function checkboxChoose(currentSelector){
    $(currentSelector).each(function(){

        var defaultVal = $(this).attr('data-default');


        var checksCount = $(this).find('.check_list input:checked').length,
//          totalChecksCount = $(this).find('.check_list input').length,
            nounOne = $(this).attr('data-one'),
            nounTwo = $(this).attr('data-two'),
            nounFive = $(this).attr('data-five');


        if(checksCount>0){

            $(this).find('.check_list li.current').removeClass('current');

            var choosenArray = [];
            $(this).find('.check_list input:checked').each(function(){
                choosenArray.push($(this).parents('label').find('.label_value, .checkbox__label').html());

                $(this).closest('li').addClass('current');
            });

            if(checksCount<4 || !$(this).hasClass('makecounts')){$(this).find('.f_value').html(defaultVal+': '+choosenArray.join(', '));}
            else {$(this).find('.f_value').html(defaultVal+': '+checksCount + ' ' + GetNoun(checksCount, nounOne, nounTwo, nounFive));}

            $(this).addClass('current');

        }
        else {

            $(this).find('.f_value').html(defaultVal);

            $(this).removeClass('current');
            $(this).find('.check_list li.current').removeClass('current');
        }

    });
}


$(document).on('change','.filter_item.checks input',function(){
    checkboxChoose($(this).parents('.filter_item.checks'));
    if($(this).parents('.radios').length && $(this).parents('.superspecial').length == 0){
        $(this).parents('.js_hasdrop').removeClass('active').find('.dropdown').stop().animate({'height':0},250,function(){
            $(this).find('.active').removeClass('active');
        });
    }
});

  // in case of F5
  checkboxChoose('.filter_item.checks');


$(function() {

    // VALUES of checkboxes in dropdowns
    $('.filter_item').each(function(){
        if(!$(this).attr('data-default')) {
            defaultVal = $(this).find('.f_value').html();
            $(this).attr('data-default', defaultVal);
        }
    });

  });

  //    close filter when click outside
  $(document).mouseup(function (e){
      var container = $(".filter_item");

      if (!container.is(e.target) // if the target of the click isn't the container...
          && container.has(e.target).length === 0) // ... nor a descendant of the container
      {
          container.closest('.js_hasdrop').removeClass('active').find('.dropdown').stop().animate({'height':0},250,function(){
              $(this).find('.active').removeClass('active');
          });
      }
  });

$(function() {
  $(document).on('click', '.js-filter-toggle', function() {
    $('.js-filters_block').slideToggle();
  });
});

$(document).on('click','.js_hasdrop .trigger, .js_hasdrop .btn_ready',function(){
    if($(this).closest('.js_hasdrop').hasClass('disabled')){
        return;
    }

    if($(this).parents('.superspecial').length == 0){

        if(!$(this).parents('.js_hasdrop').hasClass('active')){
            var targetHeight = $(this).parents('.js_hasdrop').find('.drop_content').innerHeight();

            $('.js_hasdrop.active').removeClass('active').find('.dropdown').stop().animate({'height':0},250,function(){
                $(this).find('.active').removeClass('active');
            });

            $('.js_opened_input').attr('disabled','disabled');
            $(this).parents('.js_hasdrop').find('.js_opened_input').removeAttr('disabled');
            $(this).parents('.js_hasdrop').addClass('active')
                .find('.dropdown').stop().animate({'height':targetHeight},250, function(){
                    $(this).height('auto');
                });
        }

        else {
            $(this).parents('.js_hasdrop').find('.js_opened_input').attr('disabled','disabled');
            $(this).parents('.js_hasdrop').removeClass('active').find('.dropdown').stop().animate({'height':0},250,function(){
                $(this).find('.active').removeClass('active');
            });
        }
    }
    return false;
});

// for frontend development
$(document).trigger('kdxInitPlugins');

$(".js-chosen-select").chosen({
  disable_search: true
});
//

$(document).on('kdxInitPlugins', function() {
  $(".js-chosen-select").chosen({
    disable_search: true
  });

  $(".addr-chosen-select").chosen({
    disable_search: true
  });

  $(document).on('chosen:showing_dropdown', '.addr-chosen-select', function() {
    var select = this;
      var chosenWrap = $(this).siblings('.chosen-container').eq(0);
      chosenWrap.find(".chosen-drop li").each(function(){
          var profile_id = $(select.options[$(this).attr('data-option-array-index')]).val();
          $(this).append('<span class="checkout__edit-trigger ninja--xs js_open_form" data-id="'+profile_id+'">' +
          '<svg class="icon-pencil">' +
          '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="resources/img/sprite.svg#pencil"></use>' +
          '</svg>' +
          '</span>'+
          '<span class="checkout__remove-trigger js_delete_address" data-id="'+profile_id+'"><svg class="icon-cross--big"><use xlink:href="resources/img/sprite.svg#cross"></use></svg></span>')
      })
  });

  $('.mobile .js-chosen-select').each(function() {
    if ($(this).attr('data-placeholder')) {
      var first_option = $(this).children('option').first();
      var placeholder = $(this).attr('data-placeholder');
      first_option.html(placeholder).prop('disabled', true);
    }
  });
});

$(document).on('kdxInitPlugins', function() {

// рандомный элемент закрывает фенсибокс
    $('.fancybox_close').on('click',function(){
        $.fancybox.close();
        return false;
    });


// обычный фенсибокс
    $('.js_fancybox').fancybox({
        padding: 0,
        margin:0,
        fitToView: false, // allows extend fancybox outside viewport
        helpers : {
            overlay : {
                css : {
                    'background' : 'rgba(0, 0, 0, 0.4)'
                    }
            }
        },
        afterShow:function(){
            $(document).trigger('kdxInitPlugins');
            $('.fancybox-close.fancybox-item').hide();
        }

    });


// без кнопки "закрыть"
    $('.js_fancybox2').fancybox({
        padding: 0,
        closeBtn: false,
        helpers : {
            overlay : {
                css : {
                    'background' : 'rgba(0, 0, 0, 0.25)'
                    }
            }
        }

    });
    $(".js_gallery-fancybox").fancybox({
        afterShow : function() {
            setTimeout(function(){
                $('.slider-product-big__slide-image').addClass('slider-product-big__slide-image--opened');

            },250);
        },
        afterLoad   : function() {
        $('.fancybox-wrap').addClass('gallery-fancybox');
        $('main,header').css('transition','all .3s');
        $('main,header').addClass('blur');

            var el, id = $(this.element).data('title-id');
            if (id) {
                el = $('#' + id);
                var elContent = el.html();
                if (el.length) {
                    this.inner.after('<div class="fancybox-content">' + elContent + '</div>');
                }
            }
            setTimeout(function(){
              $('.js-slider-product-main').not('.slick-initialized').slick({
                arrows: true,
                dots: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                asNavFor: '.js-slider-product-thumb',
                responsive: [
                  {
                    breakpoint: 768,
                    settings: {

                    }
                  }
                ]
              });
              $('.js-slider-product-thumb').not('.slick-initialized').slick({
                arrows: false,
                infinite: true,
                // vertical: true,
                // verticalSwiping: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                //centerPadding: "0",
                // centerMode: true,
                asNavFor: '.js-slider-product-main',
                focusOnSelect: true
              });

              // add custom current class for current thumb
              $('.js-slider-product-main').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {

                  //remove all active class
                  $('.js-slider-product-thumb .slick-slide').removeClass('current');
                  //set active class for current slide
                  var index = $('.js-slider-product-main .slick-active').data('slick-index');
                  $('.js-slider-product-thumb .slick-slide[data-slick-index='+index+']').addClass('current');
              });

              //set active class to first slide
              $('.js-slider-product-thumb .slick-slide').eq(0).addClass('current');
            }, 350);

        },
        padding: 0,
        margin: 0,
        width: '100%',
        height: '100%',
        wrapCSS: 'fullscreen-fancybox',
        helpers : {
        overlay : {
          css : {
            'background' : 'transparent'
          }
        },
        title : {
            type: 'inside'
          }
        },
        afterClose: function(){
            $('main,header').removeClass('blur');
            setTimeout(function(){
                $('main,header').css('transition','none');
            },300);
        }
    });

    $('.js_fancybox_map').fancybox({
            padding: 0,
            margin: 0,
            width: '100%',
            height: '100%',
            fitToView: false,
            helpers : {
                overlay : {
                    css : {
                        'background' : 'rgba(0, 0, 0, 0.4)'
                    }
                }
            },
            afterShow:function(){
                $(document).trigger('kdxInitPlugins');
                $('.fancybox-wrap').addClass('map-fancybox');

            }

    });
});


function initStyledMap() {
    // marker position
    var mapWrapper = $('#styled_map');
    var mapPoint = mapWrapper.data('marker').split(',');
    var markerLatitude = mapPoint[0];
    var markerLongitude = mapPoint[1];
    var $balloon = $('.balloon__content');
    var balloonTitle = $balloon.data('title');
    var balloonDescription = $balloon.data('description');

    // marker balloon
    var contentWrapper = mapWrapper.find('.balloon__wrapper');
    if (contentWrapper.length) {
        var contentString = contentWrapper[0].outerHTML;
    }

    var myMap = new ymaps.Map("styled_map", {
        center: [markerLatitude, markerLongitude],
        zoom: 16
    });

    var myPlacemark = new ymaps.Placemark([markerLatitude, markerLongitude], {
        balloonContent: '<div>' + balloonTitle + '</div><div>' + balloonDescription + '</div>',
    }, {
        iconLayout: 'default#image',
        iconImageHref: '/local/templates/form/resources/img/marker.png',
        iconImageSize: [83, 90],
    });

    myMap.geoObjects.add(myPlacemark);
}

try {
  ymaps.ready(function(){
      // init map on contacts page
      console.log($('.contacts__map #styled_map').length);
      if ($('.contacts__map #styled_map, .shop__map #styled_map').length) {
          initStyledMap();
      }
  });
} catch(e) {
  console.log(e);
}

$(document).on('kdxInitPlugins', function() {

  /*** range slider for PRICE ***/
  $('.sliderConnector').not('.sliderInited').each(function(e) {
    if (typeof window.sliderCounter == "undefined") {
      window.sliderCounter = 0;
    }
    if (typeof window.kdxSlider == "undefined") {
      window.kdxSlider = [];
    }
    window.sliderCounter++;

    var code = $(this).attr('data-code');
    var sliderValueFirst = $(this).closest('.js_slider_wrapper').find('#' + code + '-from').eq(0),
      sliderValueSecond = $(this).closest('.js_slider_wrapper').find('#' + code + '-to').eq(0);

    var startValueMin = parseInt(sliderValueFirst.val());
    var startValueMax = parseInt(sliderValueSecond.val());
    var rangeValueMin = parseInt($(this).closest('.js_slider_wrapper').find('#' + code + 'Slider').eq(0).attr('data-min-val'));
    var rangeValueMax = parseInt($(this).closest('.js_slider_wrapper').find('#' + code + 'Slider').eq(0).attr('data-max-val'));
    var rangeStep = parseInt($(this).closest('.js_slider_wrapper').find('#' + code + 'Slider').eq(0).attr('data-step'));

    var Slider = this;
    var props = {
      start: [startValueMin, startValueMax], // Handle start position
      connect: true, // Display a colored bar between the handles
      behaviour: 'tap', // Move handle on tap, bar is draggable
      range: { // Slider can select '0' to '100'
        'min': rangeValueMin,
        'max': rangeValueMax
      }
    };
    if (!isNaN(rangeStep) && rangeStep > 0) {
      props.step = rangeStep;
      props.margin = rangeStep;
    }
    noUiSlider.create(Slider, props);

    // When the slider value changes, update the input and span
    Slider.noUiSlider.on('update', function(values, handle) {
      if (handle) {
        sliderValueSecond.val(parseInt(values[handle]));
      } else {
        sliderValueFirst.val(parseInt(values[handle]));
      }
    });
    Slider.noUiSlider.on('set', function(values, handle) {
      if (typeof window.kdx_clear_filter != "undefined" && window.kdx_clear_filter) {
        return;
      }
      $(Slider).closest('form').submit()
    });

    $(document).on('change', '#' + code + '-from', function() {
      Slider.noUiSlider.set([this.value, null]);
    });
    $(document).on('change', '#' + code + '-to', function() {
      Slider.noUiSlider.set([null, this.value]);
    });

    var f_val = $(this).closest('.filter_item').find('.f_value');
    defaultVal = f_val.html();

    if (startValueMin > rangeValueMin || startValueMax < rangeValueMax)
      f_val.html(defaultVal + ': ' + '<span>' + startValueMin + '</span> — <span>' + startValueMax + '</span>');


    $(this).addClass('sliderInited');
  });

});

$(document).on('kdxInitPlugins', function() {

  // review slider
  $('.js-slick--review').not('.slick-initialized').slick({
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    fade: true,
    adaptiveHeight: true,
    autoplaySpeed: 7000
  });

  // simple image slider: blog, components, etc
  $('.js-slider-img').not('.slick-initialized').slick({
    dots: true,
    arrows: false,
    adaptiveHeight: true
  });


  // ilform tester slider
  $('.js-slider-tester').not('.slick-initialized').slick({
    dots: false,
    arrows: true
  });


  // ilform parts slider
  $('.js-slider-parts').not('.slick-initialized').slick({
    dots: false,
    arrows: true,
    adaptiveHeight: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          adaptiveHeight: false,
        }
      }
    ]
  });



  // products slider
  $('.js-slick--products').not('.slick-initialized').slick({
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });


  // product page sliders
  $('.js-slider-product-main').not('.slick-initialized').slick({
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    asNavFor: '.js-slider-product-thumb',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true
        }
      }
    ]
  });
  $('.js-slider-product-thumb').not('.slick-initialized').slick({
    arrows: true,
    infinite: false,
    // vertical: true,
    // verticalSwiping: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    //centerPadding: "0",
    //centerMode: true,
    asNavFor: '.js-slider-product-main',
    focusOnSelect: true
  });

  // add custom current class for current thumb
  $('.js-slider-product-main').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {

      //remove all active class
      $('.js-slider-product-thumb .slick-slide').removeClass('current');
      //set active class for current slide
      var index = $('.js-slider-product-main .slick-active').data('slick-index');
      $('.js-slider-product-thumb .slick-slide[data-slick-index='+index+']').addClass('current');
  });

  //set active class to first slide
  $('.js-slider-product-thumb .slick-slide').eq(0).addClass('current');


});

$(document).on('kdxInitPlugins', function() {

  // UI spinner (cart)
  if ($(".js_spinner").length > 0) {
    $(".js_spinner").not('.js_spinner_inited').each(function() {
      var option = {
        min: 1
      };
      if ($(this).attr("data-max-value")) {
        option['max'] = $(this).attr("data-max-value");
      }
      $(this).spinner(option).addClass('js_spinner_inited');
    });
  }


});

$(function() {
  svg4everybody();
});

// multuply equal height
function equalHeight(eq_group,eq_item,boxsizingCorrection) {
    $(eq_group).each(function() {
        var tallest = 0;
        var this_eq_item = $(this).find(eq_item);
        this_eq_item.css('min-height',0);
        this_eq_item.each(function() {
            var thisHeight = $(this).height()+boxsizingCorrection;
            if(thisHeight >= tallest) {
                tallest = thisHeight;
            }
        });
        this_eq_item.css('min-height',tallest);
    });

}

//single equal heights
function equalHeightSingle(group) {
    var tallest = 0;
    group.each(function() {
        var thisHeight = $(this).height();
        if(thisHeight > tallest) {
            tallest = thisHeight;
        }
    });
    group.height(tallest);
}

$(document).ready(function(){

    equalHeightSingle($(".js_equal_height"));

    equalHeight('.js_eqheight','.js_eqheight_item',0);
    $(window).resize(function(){
        equalHeight('.js_eqheight','.js_eqheight_item',0);
    });

});

// HEADER FIX ON SCROLL
function headerFix(){

    var topScroll = $('.page-header').innerHeight();

    if($(window).scrollTop() > topScroll && $(window).width() >= 1024){
    	if(!$('.fixed_head').length){
	        $('body').addClass('fixed_head');
	        $('.page-header').hide().stop().fadeTo(300,1);
    	}
    } else if($(window).scrollTop() <= topScroll){
        $('body').removeClass('fixed_head');
        $('.page-header').css('left',0);
    }
}

// headerFix();
// $(window).scroll(headerFix);

if ($('body').hasClass('mobile')) {
  $('body').removeClass('fixed_head');
}

var mobileFlag = function() {
  if ($(window).width() < 753) {
    $('body').addClass('mobile');
  } else {
    $('body').removeClass('mobile');
  }
};
mobileFlag();

$(window).resize(function() {
  mobileFlag();
});

var tabletFlag = function() {
  if ($(window).width() < 1024 && $(window).width() >= 753) {
    $('body').addClass('tablet');
  } else {
    $('body').removeClass('tablet');
  }
};
tabletFlag();

$(window).resize(function() {
  tabletFlag();
});

$(document).ready(function(){

  // search
  $(document).on('click', function(event) {
    if($(event.target).closest('.page-header__search-form, .page-header__search-trigger').length) return

    $('.page-header__search-form').fadeOut('fast');

    // if(!$(event.target).closest('.page-header__search-form').length &&
    //    !$(event.target).closest('.page-header__search-trigger').length &&
    //     $(window).width() >= 1024) {
    // } else {

    // }
  });

  $(document).on('click', '.page-header__search-trigger', function() {
    $('.page-header__search-form').fadeIn('fast');
    $('.page-header__search-input').focus();
  });

  $(document).on('click', '.image_popup', function(e){
    e.preventDefault();
    var imgLink = $(this).find('img').attr('src'),
        id = $(this).data('modal-id');
    $('#' + id).find('img').attr('src',imgLink);
    $('#' + id).fadeIn();
    $('.modal').detach().appendTo($('body'));
    $('body').addClass('overflow');
  });
  $(document).on('click', '.modal__close', function(e){
    $('.modal').fadeOut();
    $('body').removeClass('overflow');
  });

});

$(document).bind( "mouseup touchend", function (e){
    var container = $(".modal__window");

    if (!container.is(e.target)
        && container.has(e.target).length === 0)
    {
        $('.modal').fadeOut();
        $('body').removeClass('overflow');
    }

});

$(function() {

  // open/hide mobile menu on click on burger menu
  $(document).on('click', '.js-burger-toggle', function(){
    $(this).toggleClass('burger-toggle--opened');
    $('.page-header__nav-area').toggleClass('page-header__nav-area--opened');
  });

  // open/hide submenu on click submenu title
  $(document).on('click', '.navigation__submenu-toggle', function(){
    $(this).closest('.navigation__item--submenu').toggleClass('navigation__item--submenu--opened');
  });

});

// (function() {
//   var delay = false;

//   $(document).on('mousewheel DOMMouseScroll', function(event) {
//     event.preventDefault();
//     if(delay) return;
//
//     delay = true;
//     setTimeout(function(){delay = false},200)
//
//     var wd = event.originalEvent.wheelDelta || -event.originalEvent.detail;
//
//     var a= document.getElementsByTagName('a');
//     if(wd < 0) {
//       for(var i = 0 ; i < a.length ; i++) {
//         var t = a[i].getClientRects()[0].top;
//         if(t >= 40) break;
//       }
//     }
//     else {
//       for(var i = a.length-1 ; i >= 0 ; i--) {
//         var t = a[i].getClientRects()[0].top;
//         if(t < -20) break;
//       }
//     }
//     $('html,body').animate({
//       scrollTop: a[i].offsetTop
//     });
//   });
// })();
//
// if (window.addEventListener) window.addEventListener('DOMMouseScroll', wheel, false);
// window.onmousewheel = document.onmousewheel = wheel;
//
// function wheel(event) {
//     var delta = 0;
//     if (event.wheelDelta) delta = event.wheelDelta / 120;
//     else if (event.detail) delta = -event.detail / 3;
//
//     handle(delta);
//     if (event.preventDefault) event.preventDefault();
//     event.returnValue = false;
// }
//
// function handle(delta) {
//     var time = 3000; // delay time
//     // var distance = 300; // delta point
//     var distance = $('.magical-accordion').height();
//     // Dom where it will apply
//     $('html, body').stop().animate({
//         scrollTop: $(window).scrollTop() - (distance * delta)
//     }, time );
// }

// $("#button").click(function() {
//     $('html, body').animate({
//         scrollTop: $("#elementtoScrollToID").offset().top
//     }, 2000);
// });


// if (window.addEventListener) window.addEventListener('DOMMouseScroll', wheel, false);
// window.onmousewheel = document.onmousewheel = wheel;
//
// function wheel(event) {
//     var delta = 0;
//     if (event.wheelDelta) delta = event.wheelDelta / 120;
//     else if (event.detail) delta = -event.detail / 3;
//
//     handle(delta);
//     if (event.preventDefault) event.preventDefault();
//     event.returnValue = false;
// }
//
// function handle(delta) {
//     var time = 1000;
//     var distance = 300;
//
//     $('html, body').stop().animate({
//         scrollTop: $(window).scrollTop() - (distance * delta)
//     }, time );
// }


// if (window.addEventListener) window.addEventListener('DOMMouseScroll', wheel, false);
// window.onmousewheel = document.onmousewheel = wheel;
//
// function wheel(event) {
//     var delta = 0;
//     if (event.wheelDelta) delta = event.wheelDelta / 120;
//     else if (event.detail) delta = -event.detail / 3;
//
//     handle(delta);
//     if (event.preventDefault) event.preventDefault();
//     event.returnValue = false;
// }
//
// function handle(delta) {
//     var time = 1000; // delay time
//     var distance = 300; // delta point
//     // Dom where it will apply
//     $('html, body').stop().animate({
//         scrollTop: $(window).scrollTop() - (distance * delta)
//     }, time );
// }


// $(function() {
//
//     var theDestinations = $('a[name]');
//
//     theDestinations.each(function(i){
//
//         var thisDestination =  $(this),
//             thisDestinationOffset = thisDestination.offset(),
//             thisLink = $("a[href=#" + thisDestination.attr("name") + "]");
//
//         if(thisLink.length > 0) {
//             thisLink.click(function(){
//
//                 $('html,body').animate({scrollTop : thisDestinationOffset.top}, 1500);
//                 return false;
//
//             });
//         }
//     });
// });


// //Set each section's height equals to the window height
// $('section').height($(window).height());
// /*set the class 'active' to the first element
//  this will serve as our indicator*/
// $('section').first().addClass('active');
//
// /* handle the mousewheel event together with
//  DOMMouseScroll to work on cross browser */
// $(document).on('mousewheel DOMMouseScroll', function (e) {
//     e.preventDefault();//prevent the default mousewheel scrolling
//     var active = $('section.active');
//     //get the delta to determine the mousewheel scrol UP and DOWN
//     var delta = e.originalEvent.detail < 0 || e.originalEvent.wheelDelta > 0 ? 1 : -1;
//
//     //if the delta value is negative, the user is scrolling down
//     if (delta < 0) {
//         //mousewheel down handler
//         next = active.next();
//         //check if the next section exist and animate the anchoring
//         if (next.length) {
//            /*setTimeout is here to prevent the scrolling animation
//             to jump to the topmost or bottom when
//             the user scrolled very fast.*/
//             var timer = setTimeout(function () {
//                 /* animate the scrollTop by passing
//                 the elements offset top value */
//                 $('body, html').animate({
//                     scrollTop: next.offset().top
//                 }, 'slow');
//
//                 // move the indicator 'active' class
//                 next.addClass('active')
//                     .siblings().removeClass('active');
//
//                 clearTimeout(timer);
//             }, 300);
//         }
//
//     } else {
//         //mousewheel up handler
//         /*similar logic to the mousewheel down handler
//         except that we are animate the anchoring
//         to the previous sibling element*/
//         prev = active.prev();
//
//         if (prev.length) {
//             var timer = setTimeout(function () {
//                 $('body, html').animate({
//                     scrollTop: prev.offset().top
//                 }, 'slow');
//
//                 prev.addClass('active')
//                     .siblings().removeClass('active');
//
//                 clearTimeout(timer);
//             }, 300);
//         }
//
//     }
// });

$(document).on('kdxInitPlugins', function() {

  // masonry
  // re-layout masonry items after loading images for proper prositioning
  // needs 'imagesloaded' plugin

    // $('.js-masonry').imagesLoaded().progress(function() {
    //   $('.js-masonry').masonry({
    //     itemSelector: '.js-masonry__item',
    //     gutterWidth: 26,
    //     layoutPriorities: {
    //       shelfOrder: 10
    //     }
    //   });
    // });

    function masonryInit(){

         $('.js-masonry').imagesLoaded().progress(function() {
             $('.js-masonry').masonry({
               itemSelector: '.js-masonry__item',
               percentPosition: true,
               gutter: 0
             });
         });

      //  else{
      //      $('.js-masonry').imagesLoaded().progress(function() {
      //        $('.js-masonry').masonry({
      //          itemSelector: '.js-masonry__item',
      //          percentPosition: false,
      //          gutter: 10
      //        });
      //      });
      //  }
     };


    /*if (!($('body').hasClass('mobile') || $('body').hasClass('tablet'))){
      masonryInit();
      $(window).resize(masonryInit);
    };*/
});


 // function masonryInit(){
 //    if (!$('body').hasClass('mobile')){
 //      $('.js-masonry').imagesLoaded().progress(function() {
 //          $('.js-masonry').masonry({
 //            itemSelector: '.js-masonry__item',
 //            percentPosition: false,
 //            gutter: 20
 //          });
 //      });
 //    }else{
 //        $('.js-masonry').imagesLoaded().progress(function() {
 //          $('.js-masonry').masonry({
 //            itemSelector: '.js-masonry__item',
 //            percentPosition: false,
 //            gutter: 10
 //          });
 //        });
 //    }
 //  };
 //  masonryInit();
 //
 // $(window).resize(masonryInit);

// POPUPS
$(document).on('click','.js_popup',function(e){

  var popup_id = $(this).attr('data-popup');
  openPopup(popup_id);

  e.preventDefault();
})

$(document).on('click',function(e){

  if($(e.target).hasClass('popup__close') || $(e.target).hasClass('popup__wrapper')) {
      closePopups();
      e.stopPropagation();
  }

})

$(document).keyup(function(e) {
  if (e.keyCode == 27) { // esc keycode
      closePopups();
  }
});

var openPopup = function(popup_id){

  if(typeof popup_id == "undefined") {
    return false;
  }

  $('.popup').hide();
  $('.popup__wrapper').hide();
  var popup = $('.popup__wrapper .popup[data-popup-id="' + popup_id + '"]');
  if(popup.length) {
      $('body').css('overflow','hidden').addClass('hey-popup');
      popup.closest('.popup__wrapper').show();
      popup.show().find('.js_focus_on_show').each(function(){
          if($(this).val().length == 0){
              $(this).focus();
              return false;
          }
      });
  }

  if($('#styled_map').length){
    initStyledMap();
  }
}

var closePopups = function(){
  $('.popup').hide();
  $('.popup__wrapper').hide();
  $('body').css('overflow','auto');
}

$(document).on('click', '.js-prevent-default', function(e) {
  e.preventDefault();
});

/**
 * Created by Sava on 29/07/16.
 */

//there was an error in console - Stickyfill is not a function

if ( $('.sticky').length ) {
    $('.sticky').Stickyfill();
}

$('.js_tabs .js_tab_content').each(function(){$(this).addClass('hidden');});

// by default shows first tab element. Assign class current to show not first element
$('.js_tabs').each(function(){

    if(!$(this).find('.js_tab_btn.current').length){
        var tabElement = $(this).find('.js_tab_btn').first();
        var tabContent = tabElement.attr('href');
        $(tabContent).addClass('current').removeClass('hidden');
    }
});

$(document).on('click', '.js_tab_btn', function(){
    var tabElement = $(this).attr('href');
    $(tabElement).removeClass('hidden').siblings().addClass('hidden');
    $(this).addClass('current').siblings().removeClass('current');
    return false;
});

$('.js_toggle .js_toggle_content').each(function(){$(this).addClass('hidden');});

// by default shows first toggle element. Assign class current to show not first element
$('.js_toggle').each(function(){

    if(!$(this).find('.js_toggle_content.current').length){
        var toggleElement = $(this).find('.js_toggle_content').first().addClass('current').removeClass('hidden');
    }
});

$(document).on('click', '.js_toggle_btn', function(){
    var toggle_element = $(this).attr('href');
    $(toggle_element).addClass('current').removeClass('hidden');
    $(toggle_element).closest('.js_toggle_content').siblings().addClass('hidden').removeClass('current');
    return false;
});
